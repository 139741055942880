import * as React from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import { RouteComponentProps } from 'react-router';
import { Container } from '../../../layout/Container';
import { Heading } from '../../../ui/text/Heading';
import { Section } from '../../../layout/Section';
import { BackgroundLoad } from '../../../layout/BackgroundLoad';
import { BrokerItem } from '../../../ui/broker/Item';
import { Column } from '../../../layout/Column';

import { useFadeTransition } from '../../../../utils/hooks/useFadeTransition';
import { DEPARTMENT_EMPLOYEES_QUERY } from '../../../../../client/__graphql__/cluster/queries/department/employees';
import {
  DepartmentEmployeesQuery,
  DepartmentEmployeesQueryVariables
} from '../../../../__types__/generated/cluster';
import { client } from '../../../../__graphql__/clientCluster';
import { ENV_CONFIG } from '../../../../__config__/env';

interface IRoute {
  urltag: string;
}

const FinancialAdvisers: React.FC<RouteComponentProps<IRoute>> = ({
  match,
  history
}) => {
  const { data, loading } = useQuery<
    DepartmentEmployeesQuery,
    DepartmentEmployeesQueryVariables
  >(DEPARTMENT_EMPLOYEES_QUERY, {
    client,
    fetchPolicy: 'network-only',
    variables: {
      input: {
        alias: match?.params?.urltag,
        brandId: ENV_CONFIG?.BRAND_ID
      }
    }
  });

  const { fadeIn } = useFadeTransition({
    loaded: !loading
  });

  return (
    <>
      <Container style={{ paddingTop: '100px', minHeight: '650px' }}>
        <Section className="center" style={fadeIn}>
          <Heading tag="h1">Finansrådgivere</Heading>
          <BrokersOutsideWrapper>
            <BrokersWrapper className="brokers-list">
              {data?.department?.employees
                ?.filter((e) => e?.title?.match(/(Finansiell|Finans)/))
                .map((item: any, index: any) => {
                  return (
                    <Column key={`broker_${index}`} className="col">
                      <BrokerItem
                        image={item?.image?.full}
                        name={item?.name}
                        title={item?.title}
                        office={data?.department?.name}
                        phone={item?.cellphone}
                        path={item?.path}
                        showImage={true}
                      />
                    </Column>
                  );
                })}
            </BrokersWrapper>
          </BrokersOutsideWrapper>
        </Section>
      </Container>
      <BackgroundLoad
        opacity={0.25}
        position="center"
        url="https://cdn.reeltime.no/pm_assets/img/bakgrunner/falk_bakside2.jpg"
      />
    </>
  );
};

export default FinancialAdvisers;

const BrokersOutsideWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
`;

const BrokersWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 20px;

  @media all and (max-width: ${(props) => props.theme.mediaSizes.small}px) {
    grid-template-columns: 1fr;
    grid-column-gap: 0;
  }
  @media all and (min-width: ${(props) =>
      props.theme.mediaSizes.small}px) and (max-width: 800px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media all and (min-width: 800px) and (max-width: 960px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;
