import { gql } from '@apollo/client';

export const DEPARTMENT_EMPLOYEES_QUERY = gql`
  query DepartmentEmployees($input: DepartmentInput!) {
    department(input: $input) {
      alias
      brandId
      name
      employees {
        about
        brandId
        email
        employeeAlias
        employeeId
        image {
          url(size: Medium)
        }
        mobilePhone
        name
        role
        title
        workPhone
      }
    }
  }
`;
