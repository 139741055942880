import * as React from 'react';
import styled from 'styled-components';
import { Image } from './partials/Image';
import { Link, useParams } from 'react-router-dom';
import { EmailIcon, EmailOldIcon } from '../icons/Email';
import { formatPhone } from '../../../utils/format/formatPhone';

interface IBrokerItem {
  name?: string;
  title?: string;
  office?: string;
  phone?: string;
  path?: string;
  image?: string;
  showImage?: boolean;
}

export const BrokerItem: React.FC<IBrokerItem> = React.memo(
  ({ name, image, title, office, phone, path, showImage }) => {
    const params = useParams<{ urltag: string }>();
    return (
      <StyledBrokerItem>
        {showImage === true && image ? (
          <StyledBrokerImageLink to={path}>
            <Image size="large" url={image} />
          </StyledBrokerImageLink>
        ) : null}
        <StyledBrokerItemContent>
          {params?.urltag === 'hovedkontor' || !path ? (
            <StyledBrokerName className="name">{name}</StyledBrokerName>
          ) : (
            <StyledBrokerNameLink className="name" to={path}>
              {name}
            </StyledBrokerNameLink>
          )}

          {title ? <span className="title">{title}</span> : null}
          {office ? (
            <span className="office">PrivatMegleren {office}</span>
          ) : null}
          <span className="contact-info">
            {phone?.trim()?.length && params?.urltag !== 'hovedkontor' ? (
              <span className="phone">
                <a href={`tel:${phone.replace(/ /g, '')}`}>
                  {formatPhone(phone)}
                </a>
              </span>
            ) : null}
            <a href={`${path}/kontakt`}>
              <EmailOldIcon className="email-icon" />
            </a>
          </span>
          {params?.urltag !== 'hovedkontor' && path ? (
            <AboutBroker to={path}>Om megler</AboutBroker>
          ) : null}
        </StyledBrokerItemContent>
      </StyledBrokerItem>
    );
  },
  (prevProps, nextProps) => {
    if (prevProps.image === nextProps.image) {
      return true;
    }
    return false;
  }
);

const StyledBrokerItem = styled.div`
  display: flex;
  width: 100%;
  flex-flow: column;
  align-items: center;
  margin-bottom: 20px;
  float: left;
  text-decoration: none;
  font-weight: 300;

  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledBrokerNameLink = styled(Link)`
  padding: 1rem 0;
  font-size: 1.1875rem;
  color: ${(props) => props.theme.colors.primary};
  text-decoration: none;
`;

const StyledBrokerName = styled.div`
  padding: 1rem 0;
  font-size: 1.1875rem;
  color: ${(props) => props.theme.colors.primary};
  text-decoration: none;
`;

const StyledBrokerImageLink = styled(Link)``;

const StyledBrokerItemContent = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding: 0.625rem 0 0 0;
  text-align: center;

  span {
    &.title {
      font-size: 0.75rem;
      color: white;
    }
    &.office {
      margin-top: 10px;
      font-size: 0.75rem;
      color: white;
    }
    &.contact-info {
      display: flex;
      justify-content: center;
      padding: 1rem 0 0 0;
      gap: 20px;

      .email-icon {
        float: left;
      }
    }
    &.phone {
      float: left;
      a {
        text-decoration: none;
      }
    }
    &.email {
    }
  }
`;

const AboutBroker = styled(Link)`
  color: white;
  padding: 1rem 0 0.3rem 0;
  border-bottom: 2px solid #ff7e83;
  width: max-content;
  margin: 0 auto;
`;
